import React, { useContext, useState, useRef, useEffect } from "react"
import { getItemFromId, Link, Page } from "@rubendenbrok/bloqmatter-cms"
import useSafeWindowEventlistener from "../../hooks/useSafeWindowEventlistener"
import BloqmatterLink from "../atoms/BloqmatterLink"
import { DataContext } from "../layout"
import TextBasic from "../atoms/TextBasic"
import MenuIcon from "../atoms/MenuIcon"
import AnimatedMenuOpenCloseIcon from "../atoms/AnimatedMenuOpenCloseIcon"
import { Link as GatsbyLink } from "gatsby"
import LangIcon from "../../img/icons/LangIcon.svg"

type DesktopHeaderProps = {
  scrollToForm: Function
}

export default function DesktopHeader({ scrollToForm }: DesktopHeaderProps) {
  const { cssVariables, headerData, processedImageData, siteOverviewData } =
    useContext(DataContext)
  //const [showMenu, setShowMenu] = useState(false)
  const [showSideMenu, setShowSideMenu] = useState(false)
  const navbarHeight = "80px"
  const showRef = useRef(false)

  /*
  function handleScroll() {
    if (typeof window !== "undefined") {
      if (!showRef.current && window.scrollY > window.innerHeight - 100) {
        showRef.current = true
        setShowMenu(true)
      }
      if (showRef.current && window.scrollY < window.innerHeight - 200) {
        showRef.current = false
        setShowMenu(false)
      }
    }
  }
  */

  const logoImgObj = getItemFromId(
    siteOverviewData.logoImage,
    processedImageData
  )

  return (
    <>
      <header
        className={`w-full sm:inline-block hidden fixed overflow-y-visible`}
        style={{
          backgroundColor: cssVariables.colors[headerData.bgColor],
          color: cssVariables.colors[headerData.textColor],
          zIndex: 99999,
          top: "0px",
        }}
      >
        <nav
          className={`w-full flex justify-between items-center relative `}
          style={{ height: navbarHeight }}
        >
          {logoImgObj && (
            <GatsbyLink to="/">
              <img
                src={logoImgObj.urls.fallback.l}
                className="block ml-1 flex-shrink-0"
                style={{ minWidth: "150px", height: "60px" }}
                alt="KAD-audio logo"
              />
            </GatsbyLink>
          )}
          {headerData.menuItems && (
            <div className="inline-flex">
              {headerData.menuItems.map((item: any) => {
                if (
                  item.visibility === "always" ||
                  item.visibility === "desktop"
                ) {
                  return (
                    <MenuItem
                      key={item.id}
                      isDropdown={item.isDropdown}
                      childMenuItems={item.childMenuItems}
                      link={item.link}
                    />
                  )
                }
              })}
            </div>
          )}

          {/* <div className="flex">
            <div
              onClick={scrollToForm}
              className="inline-flex relative z-0 items-center mx-4 cursor-pointer hover:bg-[#f3f3f3] px-4 py-2 rounded-lg transition-colors"
            >
              <TextBasic className="xl:text-xl">
                {headerData.contactText}
              </TextBasic>
            </div>
            <LanguageSwitcher />
          </div> */}

          <div
            onClick={() => setShowSideMenu(true)}
            className="inline-flex relative z-0 items-center mr-4 cursor-pointer"
          >
            <TextBasic className="xl:text-xl">Menu</TextBasic>
            <MenuIcon
              color={cssVariables.colors[headerData.textColor]}
            ></MenuIcon>
          </div>
          <SideMenu
            showSideMenu={showSideMenu}
            setShowSideMenu={setShowSideMenu}
          />
        </nav>
      </header>
    </>
  )
}

type SideMenuProps = {
  showSideMenu: boolean
  setShowSideMenu: Function
}

function SideMenu({ showSideMenu, setShowSideMenu }: SideMenuProps) {
  const { cssVariables, headerData, thisPage, localeData } =
    useContext(DataContext)
  const menuRef = useRef<HTMLDivElement>(null)
  const visibleRef = useRef(false)

  useEffect(() => {
    if (showSideMenu) {
      visibleRef.current = true
    } else {
      visibleRef.current = false
    }
    return () => {}
  }, [showSideMenu])

  function handleClick(e: PointerEvent) {
    if (
      visibleRef.current &&
      menuRef.current &&
      !menuRef.current.contains(e.target)
    ) {
      setShowSideMenu(false)
    }
  }

  useSafeWindowEventlistener("click", handleClick)
  return (
    <div
      ref={menuRef}
      className={`h-screen w-[370px] absolute right-0 top-0 transition-transform duration-300 z-50 pt-24 pl-8 pr-12 ${
        showSideMenu ? "translate-x-0" : "translate-x-[400px]"
      } `}
      style={{
        boxShadow: "0px 0px 15px #00000044",
        backgroundColor: cssVariables.colors[headerData.bgColor],
      }}
    >
      <AnimatedMenuOpenCloseIcon
        className="absolute top-4 right-4 opacity-50"
        isOpen
        onClose={() => setShowSideMenu(false)}
        onOpen={() => {}}
      />
      {headerData.sideLinks &&
        headerData.sideLinks.map((item: any) => {
          return (
            <SideMenuItem
              setShowSideMenu={setShowSideMenu}
              key={item.link.text}
              link={item.link}
            />
          )
        })}
      <div className="">
        <SideMenuItem
          link={{
            text: "English",
            targetId: "",
            external: true,
            href: "/",
          }}
          setShowSideMenu={setShowSideMenu}
          extraMargin
          localeIsSelected={localeData.currentLocale === null}
        />
        <SideMenuItem
          link={{
            text: "Deutsch",
            targetId: "",
            external: true,
            href: "/de",
          }}
          setShowSideMenu={setShowSideMenu}
          extraMargin
          localeIsSelected={localeData.currentLocale === "de"}
        />
        <SideMenuItem
          link={{
            text: "Nederlands",
            targetId: "",
            external: true,
            href: "/nl",
          }}
          setShowSideMenu={setShowSideMenu}
          extraMargin
          localeIsSelected={localeData.currentLocale === "nl"}
        />
        <SideMenuItem
          link={{
            text: "Français",
            targetId: "",
            external: true,
            href: "/fr",
          }}
          setShowSideMenu={setShowSideMenu}
          extraMargin
          localeIsSelected={localeData.currentLocale === "fr"}
        />
        <SideMenuItem
          link={{
            text: "Castellano",
            targetId: "",
            external: true,
            href: "/se",
          }}
          setShowSideMenu={setShowSideMenu}
          extraMargin
          localeIsSelected={localeData.currentLocale === "se"}
        />
      </div>
    </div>
  )
}

type SideMenuItemProps = {
  link: Link
  setShowSideMenu: Function
  forceLocale?: string
  extraMargin?: boolean
  localeIsSelected?: boolean
}

function SideMenuItem({
  link,
  setShowSideMenu,
  extraMargin,
  localeIsSelected,
}: SideMenuItemProps) {
  const { cssVariables, headerData } = useContext(DataContext)
  const [hovered, setHovered] = useState(false)
  return (
    <BloqmatterLink link={link} forceSameTab>
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => setShowSideMenu(false)}
        className="px-6 mx-4 py-2 mb-2 font-workSans text-lg font-medium relative rounded-md transition-colors cursor-pointer"
        style={{
          color: cssVariables.colors[headerData.textColor],
          backgroundColor: hovered
            ? cssVariables.colors[headerData.accentColor]
            : "#FFFFFF00",
        }}
      >
        {extraMargin && (
          <div className="inline-block relative w-8 h-2 mr-2 -mt-2">
            <img
              src={LangIcon}
              alt="language"
              style={{ transform: "translate(-50%, -18px)" }}
              className={`absolute w-8 h-8 top-1/2 left-1/2  ${
                localeIsSelected ? "opacity-100" : "opacity-0"
              }`}
            />
          </div>
        )}
        {link.text}
      </div>
    </BloqmatterLink>
  )
}

type menuItemProps = {
  isDropdown?: boolean
  childMenuItems?: any[]
  link: Link
}

function MenuItem({ isDropdown, childMenuItems, link }: menuItemProps) {
  const [hovered, setHovered] = useState(false)
  const { cssVariables, headerData } = useContext(DataContext)
  return (
    <div
      className="relative z-50 h-full flex justify-center items-center overflow-visible"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => {
        if (!Array.isArray(childMenuItems) || !isDropdown) {
          setHovered(false)
        }
      }}
    >
      {/* PARENT LINK */}
      <BloqmatterLink link={link}>
        <MenuParentItem
          link={link}
          hovered={hovered}
          accentColor={headerData.accentColor}
        />
      </BloqmatterLink>

      {/* DROPDOWN */}
      {hovered && isDropdown && Array.isArray(childMenuItems) && (
        <div
          onMouseLeave={() => setHovered(false)}
          className="absolute top-0 left-4"
        >
          <BloqmatterLink link={link}>
            <div style={{ height: "62px" }} className=" w-full"></div>
          </BloqmatterLink>
          <div className="rounded-b-lg overflow-hidden">
            {childMenuItems.map(item => {
              if (
                item.visibility === "always" ||
                item.visibility === "desktop"
              ) {
                return (
                  <BloqmatterLink key={link.text} link={item.link}>
                    <MenuChildItem
                      link={item.link}
                      hovered={hovered}
                      accentColor={headerData.accentColor}
                    />
                  </BloqmatterLink>
                )
              }
            })}
          </div>
        </div>
      )}
    </div>
  )
}

type MenuLinkProps = {
  link: Link
  hovered: boolean
  accentColor: number
  onClick?: Function
}

function MenuParentItem({ link, hovered, accentColor }: MenuLinkProps) {
  const { cssVariables } = useContext(DataContext)
  return (
    <div
      className="lg:px-6 lg:mx-4 mx-2 px-4 py-2 h-full font-workSans text-xl relative rounded-md transition-colors"
      style={{
        backgroundColor: hovered
          ? cssVariables.colors[accentColor]
          : "#FFFFFF00",
      }}
    >
      {link.text}
    </div>
  )
}

function MenuChildItem({ link, onClick }: MenuLinkProps) {
  const [isHovered, setIsHovered] = useState(false)
  const { cssVariables, headerData } = useContext(DataContext)
  return (
    <>
      <div
        onClick={onClick ? () => onClick() : () => {}}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="pl-6 pr-12 lg:w-72 w-64 py-4 font-workSans block relative transition-colors cursor-pointer"
        style={{
          width: "",
          backgroundColor: isHovered
            ? cssVariables.colors[headerData.accentColor]
            : cssVariables.colors[headerData.bgColor],
        }}
      >
        {link.text}
      </div>
    </>
  )
}

function LanguageSwitcher() {
  const [hovered, setHovered] = useState(false)
  const { cssVariables, headerData } = useContext(DataContext)
  return (
    <div
      className="relative z-50 h-full flex justify-center items-center overflow-visible"
      onMouseEnter={() => setHovered(true)}
    >
      {/* PARENT LINK */}

      <div
        className="px-6 mx-4 py-2 h-full font-workSans text-xl relative rounded-md transition-colors"
        style={{
          backgroundColor: hovered
            ? cssVariables.colors[headerData.accentColor]
            : "#FFFFFF00",
        }}
      >
        <img src={LangIcon} alt="language" className="inline" />{" "}
      </div>

      {/* DROPDOWN */}
      {hovered && (
        <div
          onMouseLeave={() => setHovered(false)}
          className="absolute top-0 -left-20"
        >
          <div style={{ height: "62px" }} className=" w-full"></div>

          <div className="rounded-b-lg overflow-hidden">
            <a href="/">
              <MenuChildItem
                link={{
                  text: "English",
                  targetId: "/",
                  external: false,
                  href: "",
                }}
                hovered={hovered}
                accentColor={headerData.accentColor}
              />
            </a>
            <a href="/de">
              <MenuChildItem
                link={{
                  text: "Deutsch",
                  targetId: "/de",
                  external: false,
                  href: "",
                }}
                hovered={hovered}
                accentColor={headerData.accentColor}
              />
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export const extraHeaderInputs = [
  {
    inputType: "inputArray",
    keyName: "sideLinks",
    label: "Big side menu links:",
    itemName: "Link",
    childNameKey: "link",
    childInputs: [
      { inputType: "link", keyName: "link", label: "" },
      {
        inputType: "radio",
        keyName: "visibility",
        label: "Visibility",
        labelArr: ["Always", "Desktop", "Mobile"],
        valueArr: ["always", "desktop", "mobile"],
      },
    ],
  },
  { inputType: "text", keyName: "contactText", label: "Text for contact link" },
  {
    inputType: "text",
    keyName: "languageSelectText",
    label: "Text for mobile language select popup",
  },
]
