import React, { useContext, useState } from "react"
import { getItemFromId, Link, Page } from "@rubendenbrok/bloqmatter-cms"
import BloqmatterLink from "../atoms/BloqmatterLink"
import { DataContext } from "../layout"
import AnimatedMenuOpenCloseIcon from "../atoms/AnimatedMenuOpenCloseIcon"
import TextBasic from "../atoms/TextBasic"
import H3 from "../atoms/H3"
import useStore from "../../store"
import { Link as GatsbyLink } from "gatsby"
import LangIcon from "../../img/icons/LangIcon.svg"

type MobileHeaderProps = {
  scrollToForm: Function
}

export default function MobileHeader({ scrollToForm }: MobileHeaderProps) {
  const {
    headerData,
    cssVariables,
    processedImageData,
    siteOverviewData,
    localeData,
  } = useContext(DataContext)
  const [showMenu, setShowMenu] = useState(false)
  const setPopupMsg = useStore(state => state.setPopupMsg)

  const logoImgObj = getItemFromId(
    siteOverviewData.logoImage,
    processedImageData
  )

  function openLangPopup() {
    setPopupMsg({
      content: (
        <div
          className="rounded-md  pb-6 pt-2"
          style={{
            zIndex: 99999,
            backgroundColor: cssVariables.colors[headerData.bgColor],
            color: cssVariables.colors[headerData.textColor],
          }}
        >
          <H3
            className="mx-6 block my-4"
            color={cssVariables.colors[headerData.textColor]}
          >
            {headerData.languageSelectText}
          </H3>
          <a
            href="/"
            onClick={() => setPopupMsg(null)}
            className="mx-6 mt-4 text-2xl font-workSans block"
          >
            English
          </a>
          <a
            href="/de"
            onClick={() => setPopupMsg(null)}
            className="mx-6 mt-4 text-2xl font-workSans block"
          >
            Deutsch
          </a>
        </div>
      ),
    })
  }

  return (
    <>
      <header
        className="pr-4 pl-3 sm:hidden w-screen fixed left-0 h-12 flex flex-row-reverse  items-center justify-between top-0 z-50"
        style={{
          backgroundColor: cssVariables.colors[headerData.bgColor],
          zIndex: 999,
        }}
      >
        <AnimatedMenuOpenCloseIcon
          isOpen={showMenu}
          onOpen={() => setShowMenu(true)}
          onClose={() => setShowMenu(false)}
          color={cssVariables.colors[headerData.textColor]}
        />
        {/* <div className="flex">
          <div
            onClick={scrollToForm}
            className="inline-flex relative z-0 items-center mr-4 cursor-pointer  py-2 rounded-lg transition-colors"
          >
            <TextBasic className="xl:text-xl">Contact / Mailing list</TextBasic>
          </div>
          <img
            onClick={openLangPopup}
            src={LangIcon}
            alt="language"
            className="pr-2 pl-1"
          />
        </div> */}
        <div className="inline-flex items-center">
          {logoImgObj && (
            <GatsbyLink to="/">
              <img
                src={logoImgObj.urls.fallback.xs}
                className="p-2 block h-12 "
                alt="KAD-audio logo"
              />
            </GatsbyLink>
          )}
        </div>
        <nav
          className="w-full px-6 fixed flex flex-col items-start h-screen top-12 font-titleAccent"
          style={{
            backgroundColor: cssVariables.colors[headerData.bgColor],
            color: cssVariables.colors[headerData.textColor],
            right: showMenu ? 0 : "-100vw",
            transition: "right 0.5s cubic-bezier(0.76, 0, 0.24, 1)",
          }}
        >
          {headerData.logoImage && (
            <img
              src={headerData.logoImage.urls.fallback.xs}
              className="w-20 p-2"
            />
          )}
          <div className="mt-8">
            {headerData.sideLinks &&
              headerData.sideLinks.map((item: any) => {
                if (
                  item.visibility === "always" ||
                  item.visibility === "mobile"
                ) {
                  return (
                    <MenuItem
                      key={item.id}
                      isDropdown={item.isDropdown}
                      childMenuItems={item.childMenuItems}
                      link={item.link}
                      setShowMenu={setShowMenu}
                    />
                  )
                }
              })}
          </div>
          <MenuItem
            link={{
              text: "English",
              targetId: "",
              external: true,
              href: "/",
            }}
            extraMargin
            setShowMenu={setShowMenu}
            localeIsSelected={localeData.currentLocale === null}
          />
          <MenuItem
            link={{
              text: "Deutsch",
              targetId: "",
              external: true,
              href: "/de",
            }}
            extraMargin
            setShowMenu={setShowMenu}
            localeIsSelected={localeData.currentLocale === "de"}
          />
          <MenuItem
            link={{
              text: "Nederlands",
              targetId: "",
              external: true,
              href: "/nl",
            }}
            extraMargin
            setShowMenu={setShowMenu}
            localeIsSelected={localeData.currentLocale === "nl"}
          />
          <MenuItem
            link={{
              text: "Français",
              targetId: "",
              external: true,
              href: "/fr",
            }}
            extraMargin
            setShowMenu={setShowMenu}
            localeIsSelected={localeData.currentLocale === "fr"}
          />
          <MenuItem
            link={{
              text: "Castellano",
              targetId: "",
              external: true,
              href: "/se",
            }}
            extraMargin
            setShowMenu={setShowMenu}
            localeIsSelected={localeData.currentLocale === "se"}
          />
        </nav>
      </header>
    </>
  )
}

type menuItemProps = {
  isDropdown?: boolean
  childMenuItems?: any[]
  link: Link
  setShowMenu: Function
  extraMargin?: boolean
  localeIsSelected?: boolean
}

function MenuItem({
  isDropdown,
  childMenuItems,
  link,
  setShowMenu,
  extraMargin,
  localeIsSelected,
}: menuItemProps) {
  return (
    <>
      <BloqmatterLink link={link} forceSameTab>
        <div
          onClick={() => setShowMenu(false)}
          className="mx-6 mt-4 text-xl font-workSans font-medium"
        >
          {extraMargin && (
            <div className="inline w-8 h-8 mr-2">
              <img
                src={LangIcon}
                alt="language"
                className={`inline w-8 h-8 ${
                  localeIsSelected ? "opacity-100" : "opacity-0"
                }`}
              />
            </div>
          )}
          {link.text}
        </div>
      </BloqmatterLink>
      {/* DROPDOWN */}
      {isDropdown && Array.isArray(childMenuItems) && (
        <div className="mb-6">
          {childMenuItems.map(item => {
            if (item.visibility === "always" || item.visibility === "mobile") {
              return (
                <BloqmatterLink key={item.id} link={item.link}>
                  <MenuChildItem
                    link={item.link}
                    childMenuItems={childMenuItems}
                    isDropdown={isDropdown}
                    setShowMenu={setShowMenu}
                  />
                </BloqmatterLink>
              )
            }
          })}
        </div>
      )}
    </>
  )
}

function MenuChildItem({ link, setShowMenu }: menuItemProps) {
  return (
    <div
      onClick={() => setShowMenu(false)}
      className="ml-12 px-2 my-2 relative block text-lg font-workSans"
    >
      {link.text}
    </div>
  )
}
